import {
  GIFT_LOADING,
  GIFT_END_LOADING,
  GIFT_SAVE_FAIL,
  GIFT_SAVE_SUCCESS,
  GIFT_FETCHED_ONE,
  GIFT_FETCHED_ALL,
  GIFT_FETCH_FAIL,
  GIFT_DELETE_SUCCESS,
  GIFT_DELETE_FAIL,
  GIFT_UPDATE_STATUS_SUCCESS,
  GIFT_UPDATE_STATUS_ERROR,
  GIFT_UPDATE_STATUS_LOADING,
  GIFT_UPDATE_CLEAN
} from '../types/gift';

const initialState = {
  isLoading: false,
  error: null,
  hasError: false,
  gifts: [],
  changes: 0,
  isUpdateStatusLoading: false,
  isUpdateStatusSuccess: null,
  lastEventCode: null,
  lastEventValue: null
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GIFT_LOADING: {
      return {
        ...state,
        isLoading: true,
        hasError: false,
        error: null
      };
    }
    case GIFT_SAVE_SUCCESS: {
      const { gift, isNew } = payload;
      const nextGifts = state.gifts;
      if (isNew) {
        nextGifts.push(gift);
      } else {
        const index = state.gifts.findIndex((g) => g.docId === gift.docId);
        nextGifts[index] = gift;
      }
      return {
        ...state,
        hasError: false,
        gifts: [...nextGifts],
        changes: state.changes + 1,
        error: null
      };
    }
    case GIFT_SAVE_FAIL: {
      return {
        ...state,
        hasError: true,
        error: payload.error
      };
    }
    case GIFT_END_LOADING: {
      return {
        ...state,
        isLoading: false
      };
    }
    case GIFT_FETCHED_ONE: {
      return {
        ...state,
        hasError: false,
        error: null,
        gifts: state.gifts.concat(payload.selectedGift)
      };
    }
    case GIFT_FETCHED_ALL: {
      return {
        ...state,
        hasError: false,
        error: null,
        gifts: payload.gifts
      };
    }
    case GIFT_FETCH_FAIL: {
      return {
        ...state,
        hasError: true,
        error: payload.error
      };
    }
    case GIFT_DELETE_SUCCESS: {
      return {
        ...state,
        hasError: false,
        gifts: state.gifts.filter((gift) => gift.docId !== payload.id),
        error: null
      };
    }
    case GIFT_DELETE_FAIL: {
      return {
        ...state,
        hasError: true,
        error: payload
      };
    }
    case GIFT_UPDATE_STATUS_LOADING: {
      return {
        ...state,
        isUpdateStatusLoading: true,
        isUpdateStatusSuccess: null,
        error: null
      };
    }
    case GIFT_UPDATE_STATUS_ERROR: {
      return {
        ...state,
        isUpdateStatusLoading: false,
        isUpdateStatusSuccess: false,
        error: payload
      };
    }
    case GIFT_UPDATE_STATUS_SUCCESS: {
      return {
        ...state,
        gifts: state.gifts.map((gift) => (gift.docId === payload.docId ? { ...gift, ...payload } : gift)),
        isUpdateStatusLoading: false,
        isUpdateStatusSuccess: true
      };
    }
    case GIFT_UPDATE_CLEAN: {
      return {
        ...state,
        isUpdateStatusSuccess: null
      };
    }
    default: {
      return state;
    }
  }
};
