import { WINDOW_RESIZE, CHECK_LOCK } from '../const';

export const windowResize = (height, width) => ({
  type: WINDOW_RESIZE,
  payload: {
    height,
    width
  }
});

export const checkLock = () => ({
  type: CHECK_LOCK
});

export default windowResize;
