import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { getAuthIsLoggedIn } from '../../selectors/auth';
import {
  getCount, getLatestCode, getLatestType, getLatestValue
} from '../../selectors/event';

class SnackBarWrapper extends React.PureComponent {
  static propTypes = {
    latestCode: PropTypes.string,
    latestValue: PropTypes.object,
    latestType: PropTypes.string,
    count: PropTypes.number.isRequired,
    isLoggedIn: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.stack = [];
    this.state = {
      isOpen: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.count !== this.props.count) {
      this.setState({ isOpen: true });
    }
  }

  handleClose() {
    this.setState({ isOpen: false });
  }

  computeMessage() {
    const { latestCode, latestValue, latestType } = this.props;
    if (latestType === 'gift' && latestCode === 'created') {
      return `Le cadeau ${latestValue.name} à bien été créé.`;
    }
    if (latestType === 'gift' && latestCode === 'updated') {
      return `Le cadeau ${latestValue.name} à bien été mis à jour.`;
    }
    if (latestType === 'gift' && latestCode === 'removed') {
      return `Le cadeau ${latestValue.name} à bien été supprimé.`;
    }
    if (latestType === 'auth' && latestCode === 'connected') {
      return 'Vous êtes bien connecté.';
    }
    if (latestType === 'auth' && latestCode === 'disconnected') {
      return 'Vous êtes déconnecté.';
    }
    return 'Quelque chose est arrivé';
  }

  render() {
    const { isOpen } = this.state;
    const {
      latestCode, latestValue, isLoggedIn, latestType
    } = this.props;
    if (!isOpen || !isLoggedIn || !latestCode || !latestValue || !latestType) {
      return null;
    }
    const message = this.computeMessage();
    return (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          open={isOpen}
          autoHideDuration={6000}
          onClose={() => this.handleClose()}
          message={message}
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => this.handleClose()}>
                <CloseIcon fontSize="small"/>
              </IconButton>
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => ({
  latestCode: getLatestCode(store),
  latestValue: getLatestValue(store),
  latestType: getLatestType(store),
  count: getCount(store),
  isLoggedIn: getAuthIsLoggedIn(store)
});

export default connect(mapStateToProps)(SnackBarWrapper);
