import React from 'react';
import { Alert } from '@material-ui/lab';

export default class InfoMessage extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <div className='row mt-m mb-m justify-center'>
          <Alert severity='info'>
            <h3 className='m-n center'>
              Si vous voulez savoir où nous faire livrer un cadeau ou comment nous faire parvenir votre participation,
              <br/>contactez nous par mail à l&apos;adresse suivante :&nbsp;
              <a href='mailto:morgan.denis.60@gmail.com'>morgan.denis.60@gmail.com</a> ou par téléphone.
            </h3>
          </Alert>
        </div>
      </React.Fragment>);
  }
}
