import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

export default class DeleteConfirmationModal extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    name: PropTypes.string,
    onClose: PropTypes.func.isRequired
  };

  handleClose(deletion = false) {
    this.props.onClose(deletion);
  }

  render() {
    const { open, name } = this.props;
    return (
      <React.Fragment>
        <Dialog open={open} onClose={() => this.handleClose()} aria-label='modal de suppression de cadeau'>
          <DialogTitle className="closable-modal__title">
            Confirmation de suppression
            <IconButton aria-label="close" onClick={() => this.handleClose()}>
              <CloseIcon/>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              La suppression du cadeau &quot;{name}&quot; sera définitive.<br/>Voulez vous continuer ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose()}>
              Annuler
            </Button>
            <Button onClick={() => this.handleClose(true)} color='secondary'>
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}
