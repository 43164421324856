import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TextField, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { getAuthIsLoggedIn, getAuthError, getAuthHasError } from '../../selectors/auth';
import { signIn } from '../../actions/auth';

class SignIn extends React.PureComponent {
  static propTypes = {
    signIn: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    hasError: PropTypes.bool.isRequired,
    error: PropTypes.any,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isFormValid: true
    };

    if (this.props.isLoggedIn) {
      this.props.history.push('/');
    }
    document.title = 'Liste de naissance de Lucas - connexion';
  }

  componentDidUpdate() {
    if (this.props.isLoggedIn) {
      this.props.history.push('/');
    }
  }

  handleSubmit() {
    if (this.isValidForm()) {
      const { email, password } = this.state;
      this.props.signIn({ email, password });
    }
  }

  isValidForm() {
    const { email, password } = this.state;
    const status = !!(email && password);
    this.setState({ isFormValid: status });
    return status;
  }

  handleKeyUp(event) {
    if (event.keyCode === 13) {
      this.handleSubmit();
    }
  }

  onChange(key, event) {
    const value = event.target.value;
    this.setState({ [key]: value });
  }

  render() {
    const {
      isLoggedIn, hasError
    } = this.props;
    const { email, password, isFormValid } = this.state;
    return (
      <div className='container' style={{ maxWidth: '500px' }}>
        <div className='col'>
          <div className='row mt-m'>
            <h1>Connexion</h1>
          </div>
          <div className='row mt-m'>
            <TextField label='Email' placeholder='Email' value={email} fullWidth type='email'
                       onChange={(e) => this.onChange('email', e)} onKeyUp={(event) => this.handleKeyUp(event)}/>
          </div>
          <div className='row mt-m'>
            <TextField label='Mot de passe' placeholder='Mot de passe' value={password}
                       fullWidth type='password' autoComplete="off"
                       onChange={(e) => this.onChange('password', e)} onKeyUp={(event) => this.handleKeyUp(event)}/>
          </div>
          {(hasError || !isFormValid) ? (
            <div className='row mt-m'>
              <Alert severity="error" className='full'>Adresse email ou mot de passe invalide</Alert>
            </div>
          ) : null}
          <div className='row flex-end mt-m mb-l'>
            <Button
              variant="contained"
              color="primary"
              disabled={isLoggedIn}
              onClick={() => this.handleSubmit()}
            >
              Se connecter
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  isLoggedIn: getAuthIsLoggedIn(store),
  hasError: getAuthHasError(store),
  error: getAuthError(store)
});

const mapDispatchToProps = (dispatch) => ({
  signIn: ({ email, password }) => dispatch(signIn(email, password))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignIn));
