import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  Typography,
  MenuItem
} from '@material-ui/core';
import PropTypes from 'prop-types';
import MenuIcon from '@material-ui/icons/Menu';
import InfoIcon from '@material-ui/icons/Info';
import { getAuthIsLoggedIn } from '../../selectors/auth';
import { signOut } from '../../actions/auth';
import InformationModal from '../InformationModal/InformationModal';
import { getAppWidth } from '../../selectors/app';

class Header extends React.PureComponent {
  static propTypes = {
    logOut: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    appWidth: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      isInformationModalOpen: false
    };
  }

  openInformationModal() {
    this.setState({ isInformationModalOpen: true });
  }

  closeInformationModal() {
    this.setState({ isInformationModalOpen: false });
  }

  handleMenu(event) {
    this.setState({ anchorEl: event.target });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  navigateTo(path) {
    const { history } = this.props;
    this.handleClose();
    history.push(path);
  }

  handleLogOut() {
    const { logOut } = this.props;
    logOut();
    this.handleClose();
  }

  render() {
    const { anchorEl, isInformationModalOpen } = this.state;
    const { isLoggedIn, appWidth } = this.props;
    const open = Boolean(anchorEl);
    return (<React.Fragment>
      <AppBar position="static">
        <Toolbar>
          {isLoggedIn && (
            <React.Fragment>
              <IconButton edge="start" onClick={(e) => this.handleMenu(e)} color="inherit" aria-label="menu"
                          style={{ marginRight: '0.25em' }}>
                <MenuIcon/>
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={open}
                onClose={() => this.handleClose()}
              >
                <MenuItem onClick={() => this.navigateTo('/admin/gifts')}>Administration</MenuItem>
                <MenuItem onClick={() => this.handleLogOut()}>Déconnexion</MenuItem>
              </Menu>
            </React.Fragment>
          )}
          <Typography variant="h6" onClick={() => this.navigateTo('/')} className='clickable'>
            Liste de naissance{ appWidth >= 500 ? ' de Lucas' : ''}
          </Typography>
          <Typography style={{ flexGrow: 1 }}/>
          <IconButton
            aria-label="application informations"
            onClick={() => this.openInformationModal()}
            color="inherit"
          >
            <InfoIcon/>
          </IconButton>
        </Toolbar>
      </AppBar>
      <InformationModal open={isInformationModalOpen} onClose={() => this.closeInformationModal()}/>
    </React.Fragment>);
  }
}

const mapStateToProps = (store) => ({
  isLoggedIn: getAuthIsLoggedIn(store),
  isLoading: getAuthIsLoggedIn(store),
  appWidth: getAppWidth(store)
});

const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch(signOut())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
