import { getAuthIsLoggedIn } from './auth';

export const getAppState = (store) => store.app;
export const getAppVersion = (store) => (getAppState(store) ? getAppState(store).version : 'x.x.x');
export const getAppWidth = (store) => (getAppState(store) ? getAppState(store).width : 0);
export const getAppLock = (store) => {
  if (getAppState(store)) {
    if (getAuthIsLoggedIn(store)) {
      return false;
    }
    return getAppState(store).isLocked;
  }
  return false;
};
