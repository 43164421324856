import store from '../store';
import { checkLock, windowResize } from '../actions/app';

function dispatchResize() {
  store.dispatch(windowResize(document.documentElement.clientHeight, document.documentElement.clientWidth));
}

function resizeEvent() {
  window.addEventListener('resize', () => {
    dispatchResize();
  });
}

export default () => {
  store.dispatch(checkLock());
  resizeEvent();
  dispatchResize();
};
