import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/analytics';

const config = {
  apiKey: 'AIzaSyBHv5HFLvUvZyES26lD0U9WYbd28ETCJSI',
  authDomain: 'birth-morgandenis.firebaseapp.com',
  databaseURL: 'https://birth-morgandenis.firebaseio.com',
  projectId: 'birth-morgandenis',
  storageBucket: 'birth-morgandenis.appspot.com',
  messagingSenderId: '1044414478596',
  appId: '1:1044414478596:web:08eda3f5a94c92cd86f851',
  measurementId: 'G-6P36JHLQPP'
};
// Initialize Firebase
const app = firebase.initializeApp(config);
app.analytics();
export const gifts = app.firestore().collection('gifts');
export const auth = app.auth();
export const storage = app.storage().ref();
