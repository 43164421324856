export const GIFT_LOADING = 'GIFT_LOADING';
export const GIFT_END_LOADING = 'GIFT_END_LOADING';
export const GIFT_SAVE_SUCCESS = 'GIFT_SAVE_SUCCESS';
export const GIFT_SAVE_FAIL = 'GIFT_SAVE_FAIL';
export const GIFT_FETCHED_ALL = 'GIFT_FETCHED_ALL';
export const GIFT_FETCHED_ONE = 'GIFT_FETCHED_ONE';
export const GIFT_FETCH_FAIL = 'GIFT_FETCH_FAIL';
export const GIFT_DELETE_SUCCESS = 'GIFT_DELETE_SUCCESS';
export const GIFT_DELETE_FAIL = 'GIFT_DELETE_FAIL';
export const GIFT_UPDATE_STATUS_LOADING = 'GIFT_UPDATE_STATUS_LOADING';
export const GIFT_UPDATE_STATUS_SUCCESS = 'GIFT_UPDATE_STATUS_SUCCESS';
export const GIFT_UPDATE_STATUS_ERROR = 'GIFT_UPDATE_STATUS_ERROR';
export const GIFT_UPDATE_CLEAN = 'GIFT_UPDATE_CLEAN';
