import { combineReducers } from 'redux';
import app from './app';
import auth from './auth';
import gift from './gift';
import event from './event';

export default combineReducers({
  app,
  auth,
  gift,
  event
});
