import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAuthIsLoggedIn, getAuthLoading } from '../../selectors/auth';

class PrivateRoute extends React.PureComponent {
  static propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    isAuthPending: PropTypes.bool.isRequired
  };

  render() {
    const { isLoggedIn, isAuthPending, ...rest } = this.props;
    if (isAuthPending) return null;
    const element = isLoggedIn ? <Route {...rest}/> : <Redirect to={{ pathname: '/sign-in' }}/>;
    return (element);
  }
}

const mapStateToProps = (store) => ({
  isLoggedIn: getAuthIsLoggedIn(store),
  isAuthPending: getAuthLoading(store)
});

export default connect(mapStateToProps)(PrivateRoute);
