import React from 'react';
import PropTypes from 'prop-types';
import { computeStats } from '../../services/gift';
import './GiftsStats.css';
import { formatNumber } from '../../services/utils';

class GiftsStats extends React.PureComponent {
  static propTypes = {
    gifts: PropTypes.array.isRequired
  };

  render() {
    const { gifts } = this.props;
    if (!gifts) return;
    const stats = computeStats(gifts);
    return (<div>
      <div className='row wrap space-evenly'>
        <div className='col mr-s'>
          <div className='row mt-s'>
            <span className='gifts-stat-title'>Nombre de cadeaux :</span>{stats.count}
          </div>
        </div>
        <div className='col mr-s'>
          <div className='row mt-s'>
            <span className='gifts-stat-title'>Total des cadeaux :</span>{formatNumber(stats.sumTotal)} €
          </div>
        </div>
        <div className='col mr-s'>
          <div className='row mt-s'>
            <span className='gifts-stat-title'>Cadeaux financés :</span>{stats.countPayed}
          </div>
        </div>
        <div className='col'>
          <div className='row mt-s'>
            <span className='gifts-stat-title'>Total financé :</span>{formatNumber(stats.sumPayed)} €
          </div>
        </div>
      </div>
    </div>);
  }
}

export default GiftsStats;
