import { GIFT_TYPE } from '../const';
import { formatNumber } from './utils';

export const spreadGift = (gift = {}) => ({
  name: gift.name || '',
  price: gift.price || 0,
  link: gift.link || '',
  tags: gift.tags || [],
  comment: gift.comment || '',
  imageUrl: gift.imageUrl || '',
  rate: gift.rate || 0,
  type: gift.type || GIFT_TYPE.ACHAT,
  contributions: gift.contributions || [],
  createdAt: gift.createdAt || null,
  isDisabled: gift.isDisabled || false,
  docId: gift.docId || null
});

export const getImage = ({ imageUrl }) => (imageUrl || '/gift.png');

function isBooked(gift) {
  return gift.type === GIFT_TYPE.ACHAT && gift.contributions.length > 0;
}

function sumAmount({ contributions }) {
  return contributions.reduce((acc, participant) => (acc + parseFloat(participant.amount)), 0);
}

function isCompletelyPayed(gift) {
  const totalParticipated = sumAmount(gift);
  return gift.type === GIFT_TYPE.PARTICIPATION && totalParticipated >= gift.price;
}

export const giftSumpUp = (gift) => {
  const payed = sumAmount(gift);
  return {
    payed,
    names: gift.contributions.map((participant) => participant.name).join(', '),
    percentage: (payed * 100) / gift.price,
    fullyPayed: payed >= gift.price,
    rest: gift.price - payed
  };
};

export const isGiftAvailable = (gift) => (!isBooked(gift) && !isCompletelyPayed(gift));

export const filterGifts = (gifts, filterAvailable, isLoggedIn) => gifts.filter((gift) => {
  if (gift.isDisabled && !isLoggedIn) return false;
  if (filterAvailable) return isGiftAvailable(gift);
  return true;
});

const getAndParseValue = (gift, field) => {
  switch (field) {
    case 'newest':
      return gift.createdAt.seconds;
    case 'price':
    case 'rate':
      return parseFloat(gift[field]);
    default:
      return gift.name;
  }
};

export const orderGifts = (gifts, order) => {
  const [field, direction] = order.split('|');
  return gifts.sort((a, b) => {
    const parsedA = getAndParseValue(a, field);
    const parsedB = getAndParseValue(b, field);
    if (parsedA > parsedB) {
      return direction === 'asc' ? 1 : -1;
    }
    if (parsedA < parsedB) {
      return direction === 'asc' ? -1 : 1;
    }
    return 0;
  });
};

export const getStatusText = (gift, short = false) => {
  const { type } = gift;
  const {
    payed,
    names,
    percentage,
    fullyPayed,
    rest
  } = giftSumpUp(gift);
  let text = null;
  if (type === GIFT_TYPE.ACHAT) {
    if (isBooked(gift)) {
      text = short ? 'Réservé' : `Réservé par ${names}`;
    }
  } else if (type === GIFT_TYPE.PARTICIPATION) {
    if (fullyPayed) {
      text = 'Financé à 100%';
    }
    if (payed > 0) {
      if (percentage >= 100) {
        text = 'Financé à 100%';
      } else {
        text = short ? `Financé à ${formatNumber(percentage)}%` : `Financé à ${formatNumber(percentage)}% (${formatNumber(rest)} € de l'objectif)`;
      }
    }
  }
  return {
    text,
    sumUp: {
      payed,
      names,
      percentage,
      fullyPayed
    }
  };
};

export const computeStats = (gifts) => gifts.reduce((acc, gift) => {
  const isAvailable = isGiftAvailable(gift);
  const { price } = gift;
  const payed = sumAmount(gift);
  acc.count += 1;
  if (!isAvailable) {
    acc.countPayed += 1;
  }
  if (payed >= price) {
    acc.sumPayed += parseFloat(price);
  } else {
    acc.sumPayed += parseFloat(payed);
  }
  acc.sumTotal += parseFloat(price);
  return acc;
}, {
  count: 0,
  sumTotal: 0,
  countPayed: 0,
  sumPayed: 0
});

export const isNewGift = (gift) => {
  const giftTimestamp = gift.createdAt.seconds * 1000;
  const today = new Date();
  const fiveDaysEarlier = today.setDate(today.getDate() - 5);
  return fiveDaysEarlier < giftTimestamp;
};
