import React from 'react';
import PropTypes from 'prop-types';
import Gift from '../Gift/Gift';
import { orderGifts } from '../../services/gift';

export default class GiftGroup extends React.PureComponent {
  static propTypes = {
    gifts: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    order: PropTypes.string.isRequired,
    clickEvent: PropTypes.func.isRequired
  };

  onClick(gift) {
    this.props.clickEvent(gift);
  }

  render() {
    const { title, gifts, order } = this.props;
    const orderedGifts = orderGifts(gifts, order);
    return (
      <React.Fragment>
        <div className='row mt-m mb-s justify-center'>
          <h2>{title}</h2>
        </div>
        <div className='row wrap space-evenly'>
          {orderedGifts.map((gift) => (
            <Gift gift={gift} key={gift.docId} showDetails={() => this.onClick(gift)}/>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
