import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, LinearProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import {
  getGiftIsLoading,
  getAllAvailableGifts,
  getAllBookedGifts,
  getAllDisabledGifts
} from '../../../selectors/gift';
import { getGifts, removeGift } from '../../../actions/gift';
import GiftsStats from '../../../components/GiftsStats/GiftsStats';
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal/DeleteConfirmationModal';
import GiftTable from '../../../components/GiftTable/GiftTable';

class GiftsList extends React.PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    fetch: PropTypes.func.isRequired,
    availableGifts: PropTypes.array.isRequired,
    bookedGifts: PropTypes.array.isRequired,
    disabledGifts: PropTypes.array.isRequired,
    remove: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      giftToRemove: {},
      isDeletionModalOpen: false
    };
    const { availableGifts, bookedGifts, disabledGifts } = this.props;
    if (!availableGifts.length && !bookedGifts.length && !disabledGifts.length) {
      this.props.fetch();
    }
    document.title = 'Liste de naissance de Lucas - admin - list';
  }

  openDeletionModal(gift) {
    this.setState({ isDeletionModalOpen: true, giftToRemove: gift });
  }

  closeDeletionModal(confirm = false) {
    if (confirm) {
      const { giftToRemove } = this.state;
      this.props.remove(giftToRemove);
    }
    this.setState({ isDeletionModalOpen: false });
  }

  edit(docId) {
    this.props.history.push(`/admin/gifts/edit/${docId}`);
  }

  render() {
    const {
      availableGifts, bookedGifts, disabledGifts, isLoading
    } = this.props;
    const { isDeletionModalOpen, giftToRemove } = this.state;
    return (<React.Fragment>
      <div className='row'>
        <div className='col full'>
          <div className='row space-between mt-s mb-m'>
            <div className='col'>
              <div className='row'>
                <h1>Administration</h1>
              </div>
            </div>
            <div className='col'>
              <div className='row'>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon/>}
                  onClick={() => this.props.history.push('/admin/gifts/create')}
                >
                  Créer un cadeau
                </Button>
              </div>
            </div>
          </div>
          <GiftsStats gifts={[...availableGifts, ...bookedGifts]}/>
          { isLoading ? (<LinearProgress className='mt-xs mb-xs'/>) : (<div className='row mt-s' style={{ height: '4px' }}/>) }
          <GiftTable gifts={availableGifts} title='Cadeaux disponibles' onEdit={(id) => this.edit(id)} onDelete={(gift) => this.openDeletionModal(gift)}/>
          <GiftTable gifts={bookedGifts} title='Cadeaux réservés' contributorsCol={true} onEdit={(id) => this.edit(id)} onDelete={(gift) => this.openDeletionModal(gift)}/>
          <GiftTable gifts={disabledGifts} title='Cadeaux désactivés' onEdit={(id) => this.edit(id)} onDelete={(gift) => this.openDeletionModal(gift)}/>
        </div>
      </div>
      <DeleteConfirmationModal open={isDeletionModalOpen} name={giftToRemove.name}
                               onClose={(action) => this.closeDeletionModal(action)}/>
    </React.Fragment>);
  }
}

const mapStateToProps = (store) => ({
  isLoading: getGiftIsLoading(store),
  availableGifts: getAllAvailableGifts(store),
  bookedGifts: getAllBookedGifts(store),
  disabledGifts: getAllDisabledGifts(store)
});

const mapDispatchToProps = (dispatch) => ({
  fetch: () => dispatch(getGifts()),
  remove: (gift) => dispatch(removeGift(gift))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GiftsList));
