import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Chip
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import ShareIcon from '@material-ui/icons/Share';
import './GiftDetailsModal.css';
import { updateStatus } from '../../actions/gift';
import { getImage, isGiftAvailable } from '../../services/gift';
import GiftAvailability from '../GiftAvailability/GiftAvailability';
import { GIFT_TYPE } from '../../const';
import { formatNumber } from '../../services/utils';
import { getAppLock, getAppWidth } from '../../selectors/app';
import BookingModal from '../BookingModal/BookingModal';
import ParticipationModal from '../ParticipationModal/ParticipationModal';

class GiftDetailsModal extends React.PureComponent {
  static propTypes = {
    gift: PropTypes.object,
    back: PropTypes.func.isRequired,
    updateStatus: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired,
    isAppLocked: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isPending: false,
      isParticipationModalOpen: false,
      isBookingModalOpen: false
    };
  }

  handleClick() {
    this.props.back();
  }

  openParticipatingModal() {
    this.setState({ isParticipationModalOpen: true });
  }

  closeParticipationModal(hasFinish = false) {
    this.setState({ isParticipationModalOpen: false });
    if (hasFinish) this.props.back();
  }

  openBookingModal() {
    this.setState({ isBookingModalOpen: true });
  }

  closeBookingModal(hasFinish = false) {
    this.setState({ isBookingModalOpen: false });
    if (hasFinish) this.props.back();
  }

  renderButton() {
    const { gift, isAppLocked } = this.props;
    const isAvailable = isGiftAvailable(gift);
    const { isParticipatingPanelOpen } = this.state;
    if (gift.type === GIFT_TYPE.ACHAT) {
      return (<Button color="primary" disabled={!isAvailable || isAppLocked} onClick={() => this.openBookingModal()}>
        Réserver
      </Button>);
    }
    if (gift.type === GIFT_TYPE.PARTICIPATION && !isParticipatingPanelOpen) {
      return (<Button color="primary" disabled={!isAvailable || isAppLocked} onClick={() => this.openParticipatingModal()}>
        Participer
      </Button>);
    }
    return null;
  }

  handleClose() {
    this.props.onClose();
  }

  render() {
    const { gift, open, width } = this.props;
    const { isBookingModalOpen, isParticipationModalOpen } = this.state;
    if (!gift) return null;
    const hasTags = gift.tags && gift.tags.length > 0;
    return (
      <React.Fragment>
        <Dialog
          fullWidth={true}
          open={open}
          maxWidth='sm'
          fullScreen={width < 600}
          onClose={() => this.handleClose()}
        >
          <DialogTitle className="closable-modal__title">
            {gift.name}
            <IconButton aria-label="close" onClick={() => this.handleClose()}>
              <CloseIcon/>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div className='row'>
              <div className='col gift-modal__image-col'>
                <img src={getImage(gift)} alt={gift.name} className="gift-modal__image"/>
              </div>
              <div className='col space-evenly'>
                <div className='row ml-l mt-s'>
                  <div className='col justify-center'>
                    <h4>Prix&nbsp;:</h4>
                  </div>
                  <div className='col ml-xs'>{formatNumber(gift.price)} €</div>
                </div>
                {hasTags ? (
                  <div className='row ml-l mt-xs'>
                    <div className='col justify-center'>
                      <h4>Tags&nbsp;:</h4>
                    </div>
                    <div className='col ml-xs'>
                      <div className='row wrap'>
                        {
                          gift.tags.map((tag, i) => (
                            <Chip size='small' key={i + tag} className='mr-xs mt-xs' label={tag}/>
                          ))
                        }
                      </div>
                    </div>
                  </div>) : null}
                {gift.link ? (<div className='row ml-l mt-s'>
                  <h4>
                    <a href={gift.link} target='_blank' rel='noopener noreferrer' className='link'>
                      Voir le site <ShareIcon style={{ fontSize: '1em' }}/>
                    </a>
                  </h4>
                </div>) : null}
                {gift.comment ? (
                  <div className='row ml-l mt-s'>
                    <div className='col justify-center'>
                      <h4>Commentaire&nbsp;:</h4>
                    </div>
                    <div className='col ml-xs'>{gift.comment}</div>
                  </div>) : null}
                <div className='row ml-l mt-s'>
                  <div className='col justify-center'>
                    <h4>Nécessité&nbsp;:&nbsp;</h4>
                  </div>
                  <div className='col'>
                    <Rating value={gift.rate || 0} readOnly/>
                  </div>
                </div>
                <GiftAvailability gift={gift}/>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClick()}>
              Retour
            </Button>
            {this.item}
            {this.renderButton()}
          </DialogActions>
        </Dialog>
        <BookingModal open={isBookingModalOpen} onClose={(response) => this.closeBookingModal(response)} gift={gift}/>
        <ParticipationModal open={isParticipationModalOpen}
                            onClose={(response) => this.closeParticipationModal(response)} gift={gift}/>
      </React.Fragment>);
  }
}

const mapStateToProps = (store) => ({
  width: getAppWidth(store),
  isAppLocked: getAppLock(store)
});

const mapDispatchToProps = (dispatch) => ({
  updateStatus: (id, data) => dispatch(updateStatus(id, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(GiftDetailsModal);
