import { isGiftAvailable } from '../services/gift';

export const getGiftState = (store) => store.gift;
export const getGiftIsLoading = (store) => (getGiftState(store) ? getGiftState(store).isLoading : false);
export const getAllDisabledGifts = (store) => (
  getGiftState(store) ? getGiftState(store).gifts.filter((gift) => gift.isDisabled) : []
);
export const getAllAvailableGifts = (store) => (
  getGiftState(store) ? getGiftState(store).gifts.filter((gift) => (!gift.isDisabled && isGiftAvailable(gift))) : []
);
export const getAllBookedGifts = (store) => (
  getGiftState(store) ? getGiftState(store).gifts.filter((gift) => (!gift.isDisabled && !isGiftAvailable(gift))) : []
);
export const getGift = (store, id) => (getGiftState(store).gifts.find((gift) => gift.docId === id));
export const getGiftChanges = (store) => (getGiftState(store) ? getGiftState(store).changes : 0);
export const getGiftUpdateStatusLoading = (store) => (
  getGiftState(store) ? getGiftState(store).isUpdateStatusLoading : false
);
export const getGiftUpdateStatusResult = (store) => (
  getGiftState(store) ? getGiftState(store).isUpdateStatusSuccess : null
);
