import React from 'react';
import PropTypes from 'prop-types';
import { Button, DialogActions, DialogContent } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import './FailAction.css';

export default class FailAction extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired
  };

  render() {
    const { onClose } = this.props;
    return (
      <React.Fragment>
        <DialogContent>
          <div className='col full justify-center'>
            <div className='row justify-center'>
              <ErrorOutlineIcon className='fail-action__icon'/>
            </div>
            <div className='row mt-l justify-center center'>
              <h2 className='fail-action__text'>Une erreur est survenue.</h2>
            </div>
            <div className='row mt-m justify-center center'>
              <h3 className='fail-action__text'>Si cela se reproduit contactez moi à l&apos;adresse suivante :</h3>
            </div>
            <div className='row justify-center mb-l'>
              <a href='mailto:morgan.denis.60@gmail.com?subject=Problème%20sur%20la%20liste%20de%20naissance'>
                morgan.denis.60@gmail.com
              </a>
            </div>
          </div>
        </DialogContent>
        <DialogActions className='fail-action__button__row'>
          <Button onClick={() => onClose()} color='secondary'>
            Retour
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  }
}
