import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, CircularProgress, DialogTitle, IconButton
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { getGiftUpdateStatusLoading, getGiftUpdateStatusResult } from '../../selectors/gift';
import { cleanUpdateStatusResults, updateStatus } from '../../actions/gift';
import SuccessAction from '../SuccessAction/SuccessAction';
import FailAction from '../FailAction/FailAction';
import { giftSumpUp } from '../../services/gift';
import { formatNumber } from '../../services/utils';
import { getAppLock } from '../../selectors/app';

class ParticipationModal extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    hasSuccess: PropTypes.any,
    clean: PropTypes.func.isRequired,
    gift: PropTypes.object.isRequired,
    updateStatus: PropTypes.func.isRequired,
    isAppLocked: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      amount: 0,
      error: ''
    };
    this.sumUp = giftSumpUp(this.props.gift);
  }

  participateToGift() {
    if (this.isFormValid()) {
      const { amount, name } = this.state;
      const { contributions, docId } = this.props.gift;
      const giftChanges = { contributions: contributions.concat([{ name, amount: parseFloat(amount) }]) };
      this.props.updateStatus(docId, giftChanges);
    }
  }

  finish() {
    this.props.onClose(true);
    this.cleanState();
    this.props.clean();
  }

  isFormValid() {
    const { gift } = this.props;
    const { amount, name } = this.state;
    if (name === '') {
      this.setState({ error: 'Vous devez saisir un nom.' });
      return false;
    }
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(parseFloat(amount)) || parseFloat(amount) <= 0) {
      this.setState({ error: 'Vous devez saisir un montant positif.' });
      return false;
    }
    if (parseFloat(amount) > this.sumUp.rest) {
      this.setState({
        error: `Cet article à déjà été financé à hauteur de ${formatNumber(this.sumUp.payed)}€ (prix initial de ${formatNumber(gift.price)}€).<br/>Vous devez donc saisir un montant inférieur ou égal à ${formatNumber(this.sumUp.rest)}.`
      });
      return false;
    }
    this.setState({ error: '' });
    return true;
  }

  onChange(event, key) {
    const { value } = event.target;
    this.setState({ [key]: value });
  }

  cleanState() {
    this.setState({ name: '', amount: 0, error: '' });
  }

  handleClose() {
    this.cleanState();
    this.props.onClose();
  }

  render() {
    const {
      open, isLoading, onClose, hasSuccess, clean, isAppLocked
    } = this.props;
    const { name, amount, error } = this.state;
    return (
      <React.Fragment>
        <Dialog open={open} onClose={() => this.handleClose()}>
          <DialogTitle className="closable-modal__title">
            Participation
            <IconButton aria-label="close" onClick={() => onClose()}>
              <CloseIcon/>
            </IconButton>
          </DialogTitle>
          {hasSuccess === null ? (
            <React.Fragment>
              <DialogContent>
                <DialogContentText>
                  Enregistrez les informations de votre participation en indiquant vos nom et prénom ainsi
                  que le montant que vous désirez mettre.
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  value={name}
                  label="Nom et prénom"
                  type='text'
                  fullWidth
                  onChange={(e) => this.onChange(e, 'name')}
                />
                <TextField
                  margin="dense"
                  value={amount}
                  label="Montant"
                  type='number'
                  fullWidth
                  onChange={(e) => this.onChange(e, 'amount')}
                />
                { error !== '' ? (<Alert severity="error"><span dangerouslySetInnerHTML={{ __html: error }}/></Alert>) : null }
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.handleClose()}>Annuler</Button>
                <Button onClick={() => this.participateToGift()} color="primary" disabled={isAppLocked || isLoading}
                        startIcon={isLoading ? (<CircularProgress size={20}/>) : null}>
                  Valider ma participation
                </Button>
              </DialogActions>
            </React.Fragment>) : null}
          {(!isLoading && hasSuccess === false) ? (<FailAction onClose={() => clean()}/>) : null}
          {(!isLoading && hasSuccess === true) ? (
            <SuccessAction onClose={() => this.finish()} actionName={'participation'}/>) : null}
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: getGiftUpdateStatusLoading(state),
  hasSuccess: getGiftUpdateStatusResult(state),
  isAppLocked: getAppLock(state)
});

const mapDispatchToProps = (dispatch) => ({
  clean: () => dispatch(cleanUpdateStatusResults),
  updateStatus: (id, data) => dispatch(updateStatus(id, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ParticipationModal);
