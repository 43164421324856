export const extractUseFullImageUrl = (fbImageUrl) => fbImageUrl.replace(/&token=[a-f0-9-]{36}/ig, '');

export const extractUuidFromImageUrl = (fbImageUrl) => {
  const match = fbImageUrl.match(/gifts%2F([a-f0-9-]{36})/g);
  if (match.length === 0) {
    throw new Error('No uuid in the image URL');
  }
  return match[0].replace('gifts%2F', '');
};

export const formatNumber = (value) => parseFloat(value).toFixed(2).replace('.', ',');

export const formatDate = (timestamp, isGift = false) => {
  const realTimestamp = timestamp * (isGift ? 1000 : 1);
  const date = new Date(realTimestamp);
  return date.toLocaleDateString();
};

export const getWebsite = (url) => url
  .replace('https://', '')
  .replace('http://', '')
  .split('/')[0]
  .replace('www.', '')
  .split(/\./g)[0];
