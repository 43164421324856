import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import './NewIcon.css';
import { formatDate } from '../../services/utils';

export default class NewIcon extends React.PureComponent {
  static propTypes = {
    giftCreatedAt: PropTypes.object.isRequired
  };

  render() {
    const { giftCreatedAt: { seconds } } = this.props;
    const text = `Nouveau ! ajouté le ${formatDate(seconds, true)}`;
    return (
      <Tooltip title={text} placement='top' aria-label="adding date">
        <StarIcon className='new-gift__tooltip-icon'/>
      </Tooltip>
    );
  }
}
