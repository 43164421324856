import React from 'react';
import './App.css';
import { HashRouter as Router } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import MainContainer from './components/MainContainer/MainContainer';
import { auth } from './firebase';
import store from './store';
import {
  AUTH_LOADING, AUTH_LOADING_FINISHED, SIGN_OUT, SIGN_SUCCESS
} from './types/auth';
import Header from './components/Header/Header';
import SnackBarWrapper from './components/SnackBarWrapper/SnackBarWrapper';

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    store.dispatch({ type: AUTH_LOADING });

    this.theme = createMuiTheme({
      palette: {
        primary: {
          main: '#7ed739'
        },
        secondary: {
          main: '#a30200'
        },
        text: {
          primary: '#233140'
        }
      }
    });
  }

  componentDidMount() {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        store.dispatch({ type: SIGN_SUCCESS, payload: { user: { email: user.email } } });
      } else {
        store.dispatch({ type: SIGN_OUT });
      }
      store.dispatch({ type: AUTH_LOADING_FINISHED });
    });
  }

  render() {
    return (
      <Router>
        <ThemeProvider theme={this.theme}>
          <div className="App main-container">
            <Header/>
            <MainContainer/>
            <SnackBarWrapper/>
          </div>
        </ThemeProvider>
      </Router>
    );
  }
}

export default App;
