import React from 'react';
import './Spinner.css';

export default class Spinner extends React.PureComponent {
  render() {
    return (
      <div className='row justify-center' style={{ height: '100%', minHeight: '200px' }}>
        <div className='col full justify-center'>
          <div className="loader"><span/><span/><span/><span/></div>
        </div>
      </div>);
  }
}
