import React from 'react';
import PropTypes from 'prop-types';
import { getStatusText, isGiftAvailable } from '../../services/gift';
import { GIFT_TYPE } from '../../const';

export default class GiftAvailability extends React.PureComponent {
  static propTypes = {
    gift: PropTypes.object.isRequired
  };

  render() {
    const { gift } = this.props;
    const isAvailable = isGiftAvailable(gift);
    const { text, sumUp } = getStatusText(gift);

    if (GIFT_TYPE.ACHAT && !isAvailable) {
      return (
        <div className='row ml-l mt-s'>
          <h4>{text}</h4>
        </div>
      );
    }
    if (GIFT_TYPE.PARTICIPATION) {
      return (
        <div className='row ml-l mt-s'>
          <div className='col full'>
            <div className='row'>
              <h4>{text}</h4>
            </div>
            {sumUp.names ? (<div className='row mt-xs'>
              <h5 className='ml-s'>Par : {sumUp.names}</h5>
            </div>) : null}
          </div>
        </div>
      );
    }
    return null;
  }
}
