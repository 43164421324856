import React from 'react';
import { Alert } from '@material-ui/lab';

export default class AppLocked extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <div className='col full justify-center'>
          <div className='row mt-m mb-m justify-center'>
            <Alert severity='error' className='mt-l'>
              <h3 className='m-n center'>
                Il n&apos;est plus possible de réserver ou de participer à cette liste depuis le 1<sup>er</sup> avril
                2020.
              </h3>
            </Alert>
          </div>
        </div>
      </React.Fragment>);
  }
}
