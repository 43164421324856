import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Card, CardActions, CardHeader, CardMedia, Button, IconButton, CardContent, MenuItem, Menu
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import './Gift.css';
import { getImage, isGiftAvailable, isNewGift } from '../../services/gift';
import { formatNumber } from '../../services/utils';
import { getAuthIsLoggedIn } from '../../selectors/auth';
import { removeGift } from '../../actions/gift';
import NewIcon from '../NewIcon/NewIcon';
import DeleteConfirmationModal from '../DeleteConfirmationModal/DeleteConfirmationModal';
import { getAppLock } from '../../selectors/app';


class Gift extends React.PureComponent {
  static propTypes = {
    gift: PropTypes.object.isRequired,
    showDetails: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    remove: PropTypes.func.isRequired,
    isAppLocked: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      anchorEl: null,
      isDeletionModalOpen: false,
      isNew: isNewGift(this.props.gift),
      isDisabled: this.props.isLoggedIn && this.props.gift.isDisabled
    };
    this.observer = new IntersectionObserver((entries) => this.intersectionCallback(entries), {
      root: null,
      rootMargin: '0px',
      threshold: [0.01]
    });
  }

  componentDidMount() {
    this.observer.observe(document.querySelector(`.gift-${this.props.gift.docId}`));
  }

  componentWillUnmount() {
    this.observer.unobserve(document.querySelector(`.gift-${this.props.gift.docId}`));
  }

  intersectionCallback(entries) {
    const isVisible = entries[0].intersectionRatio > 0.01;
    if (isVisible) {
      this.observer.unobserve(document.querySelector(`.gift-${this.props.gift.docId}`));
    }
    this.setState({ isVisible });
  }

  navigateTo(path) {
    const { history } = this.props;
    this.handleClose();
    history.push(path);
  }

  handleMenu(event) {
    const { anchorEl } = this.state;
    if (anchorEl === null) {
      this.setState({ anchorEl: event.target });
    }
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  edit(docId) {
    this.props.history.push(`/admin/gifts/edit/${docId}`);
  }

  openGiftPrompt() {
    this.setState({ anchorEl: null, isDeletionModalOpen: true });
  }

  closeDeletionModal(confirm = false) {
    if (confirm) {
      const { gift } = this.props;
      this.props.remove(gift);
    }
    this.setState({ anchorEl: null, isDeletionModalOpen: false });
  }

  getActions() {
    const { isLoggedIn } = this.props;
    const { anchorEl } = this.state;
    const { gift } = this.props;
    const open = Boolean(anchorEl);
    return isLoggedIn ? (<IconButton aria-label="settings" onClick={(e) => this.handleMenu(e)}>
      <MoreVertIcon/>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        onClose={() => this.handleClose()}
      >
        <MenuItem onClick={() => this.navigateTo(`/admin/gifts/edit/${gift.docId}`)}>Modifier</MenuItem>
        <MenuItem onClick={() => this.openGiftPrompt()}>Supprimer</MenuItem>
      </Menu>
    </IconButton>) : null;
  }

  getAvatar() {
    const { gift } = this.props;
    const { isNew, isDisabled } = this.state;
    const disableIcon = isDisabled ? (<VisibilityOffIcon className='gift-card__disabled__icon'/>) : null;
    return !disableIcon && isNew ? (<NewIcon giftCreatedAt={gift.createdAt}/>) : disableIcon;
  }

  render() {
    const { gift, isAppLocked } = this.props;
    const {
      isVisible, isDeletionModalOpen, isNew, isDisabled
    } = this.state;
    const isAvailable = isGiftAvailable(gift);
    const isDisableClass = isDisabled ? 'gift-card--disabled' : '';
    const isNewClass = isNew ? 'gift-card--new' : '';
    const avatar = this.getAvatar();
    return (<React.Fragment>
      <Card className={`gift-card m-s gift-${gift.docId} ${isNewClass} ${isDisableClass}`}>
        <CardHeader title={gift.name} className='center' avatar={avatar} action={this.getActions()}/>
        <CardMedia className='gift-card-bloc-image-height'>
          {isVisible ? <img src={getImage(gift)} alt={gift.name} className='gift-card-bloc-image'/> : (<div/>)}
        </CardMedia>
        <CardContent>
          <div className='row mt-s justify-center'>
            <div className="gift-bloc-price">{formatNumber(gift.price)} €</div>
          </div>
          <div className='row mt-s justify-center'>
            <div className="gift-bloc-rate">
              <Rating name="besoin" value={gift.rate || 0} readOnly/>
            </div>
          </div>
        </CardContent>
        <CardActions className='gift-card-actions'>
          { isAvailable ? (
            <Button variant="contained" color="primary" onClick={() => this.props.showDetails(gift.docId)} disabled={isAppLocked}>
              { gift.type === 'achat' ? 'Réserver' : 'Participer' }
            </Button>
          ) : (
            <Button variant="contained" onClick={() => this.props.showDetails(gift.docId)} disabled={isAppLocked}>
              Réservé
            </Button>
          )}
        </CardActions>
      </Card>
      <DeleteConfirmationModal open={isDeletionModalOpen} onClose={(action) => this.closeDeletionModal(action)}
                               name={gift.name}/>
    </React.Fragment>);
  }
}

const mapStateToProps = (store) => ({
  isLoggedIn: getAuthIsLoggedIn(store),
  isAppLocked: getAppLock(store)
});

const mapDispatchToProps = (dispatch) => ({
  remove: (gift) => dispatch(removeGift(gift))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Gift));
