import SignIn from '../screens/SignIn/SignIn';
import Gifts from '../screens/Gifts/Gifts';
import NotFound from '../components/NotFound/NotFound';
import GiftsList from '../screens/Admin/GiftsList/GiftsList';
import GiftEdit from '../screens/Admin/GiftEdit/GiftEdit';

export default [
  {
    path: '/',
    exact: true,
    component: Gifts,
    name: 'Liste de naissance de Lucas',
    meta: {
      visible: true
    }
  }, {
    path: '/sign-in',
    component: SignIn,
    name: 'Connexion',
    meta: {
      visible: false,
      noAuthOnly: true
    }
  }, {
    path: '/admin/gifts/create',
    component: GiftEdit,
    name: 'Création de cadeau',
    meta: {
      visible: false,
      authOnly: true
    }
  }, {
    path: '/admin/gifts/edit/:gift',
    component: GiftEdit,
    name: 'Modification de cadeau',
    meta: {
      visible: false,
      authOnly: true
    }
  }, {
    path: '/admin/gifts',
    component: GiftsList,
    name: 'Administration',
    meta: {
      visible: true,
      authOnly: true
    }
  }, {
    path: '*',
    component: NotFound,
    meta: {
      visible: false
    }
  }
];
