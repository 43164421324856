import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress, IconButton
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { getGiftUpdateStatusLoading, getGiftUpdateStatusResult } from '../../selectors/gift';
import { cleanUpdateStatusResults, updateStatus } from '../../actions/gift';
import FailAction from '../FailAction/FailAction';
import SuccessAction from '../SuccessAction/SuccessAction';
import { getAppLock } from '../../selectors/app';

class BookingModal extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    hasSuccess: PropTypes.any,
    clean: PropTypes.func.isRequired,
    gift: PropTypes.object.isRequired,
    updateStatus: PropTypes.func.isRequired,
    isAppLocked: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      error: ''
    };
  }

  bookGift() {
    if (this.isFormValid()) {
      const { name } = this.state;
      const { contributions, price, docId } = this.props.gift;
      const giftChanges = { contributions: contributions.concat([{ name, amount: price }]) };
      this.props.updateStatus(docId, giftChanges);
    }
  }

  isFormValid() {
    const { name } = this.state;
    if (!name) {
      this.setState({ error: 'Vous devez saisir un nom.' });
      return false;
    }
    this.setState({ error: '' });
    return true;
  }

  finish() {
    this.props.onClose(true);
    this.cleanState();
    this.props.clean();
  }

  cleanState() {
    this.setState({ name: '', error: '' });
  }

  handleClose() {
    this.cleanState();
    this.props.onClose();
  }

  onChange(event) {
    const { value } = event.target;
    this.setState({ name: value });
  }

  render() {
    const {
      open, isLoading, hasSuccess, clean, isAppLocked
    } = this.props;
    const { name, error } = this.state;
    return (
      <React.Fragment>
        <Dialog open={open} onClose={() => this.handleClose()} aria-label="modal de réservation de cadeau">
          <DialogTitle className="closable-modal__title">
            Réservation
            <IconButton aria-label="close" onClick={() => this.handleClose()}>
              <CloseIcon/>
            </IconButton>
          </DialogTitle>
          {hasSuccess === null ? (
            <React.Fragment>
              <DialogContent>
                <DialogContentText>
                  Indiquez vos nom et prénom afin de valider la réservation
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  value={name}
                  label="Nom et prénom"
                  type='text'
                  fullWidth
                  onChange={(e) => this.onChange(e)}
                />
                { error !== '' ? (<Alert severity='error'>{error}</Alert>) : null }
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.handleClose()}>Annuler</Button>
                <Button onClick={() => this.bookGift()} color="primary" disabled={isAppLocked || isLoading}
                        startIcon={isLoading ? (<CircularProgress size={20}/>) : null}>
                  Valider ma réservation
                </Button>
              </DialogActions>
            </React.Fragment>) : null}
          {(!isLoading && hasSuccess === false) ? (<FailAction onClose={() => clean()}/>) : null}
          {(!isLoading && hasSuccess === true) ? (
            <SuccessAction onClose={() => this.finish()} actionName={'réservation'}/>) : null}
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: getGiftUpdateStatusLoading(state),
  hasSuccess: getGiftUpdateStatusResult(state),
  isAppLocked: getAppLock(state)
});

const mapDispatchToProps = (dispatch) => ({
  clean: () => dispatch(cleanUpdateStatusResults),
  updateStatus: (id, data) => dispatch(updateStatus(id, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingModal);
