import { v4 as uuid } from 'uuid';
import {
  GIFT_END_LOADING,
  GIFT_LOADING,
  GIFT_SAVE_FAIL,
  GIFT_SAVE_SUCCESS,
  GIFT_FETCHED_ALL,
  GIFT_FETCH_FAIL,
  GIFT_DELETE_SUCCESS,
  GIFT_DELETE_FAIL,
  GIFT_UPDATE_STATUS_ERROR,
  GIFT_UPDATE_STATUS_SUCCESS,
  GIFT_UPDATE_STATUS_LOADING,
  GIFT_UPDATE_CLEAN
} from '../types/gift';
import { storage, gifts } from '../firebase';
import { extractUseFullImageUrl, extractUuidFromImageUrl } from '../services/utils';
import { EVENT_GIFT } from '../types/event';
import { LOCK_DATE } from '../const';

export const createGift = (gift, file) => async (dispatch) => {
  const fileRef = storage.child(`gifts/${uuid()}`);
  dispatch({ type: GIFT_LOADING });
  const meta = { createdAt: new Date(), updatedAt: new Date(), docId: uuid() };
  const giftToSave = { ...gift, ...meta };

  if (file) {
    try {
      await fileRef.put(file, { cacheControl: 'public,max-age=3000000' });
      const fbImageUrl = await fileRef.getDownloadURL();
      giftToSave.imageUrl = extractUseFullImageUrl(fbImageUrl);
    } catch (e) {
      dispatch({ type: GIFT_SAVE_FAIL, payload: e });
    }
  }

  try {
    await gifts.doc(giftToSave.docId).set(giftToSave);
    dispatch({ type: GIFT_SAVE_SUCCESS, payload: { gift: giftToSave, isNew: true } });
    dispatch({ type: EVENT_GIFT, payload: { type: 'gift', code: 'created', value: giftToSave } });
  } catch (e) {
    await fileRef.delete().catch(() => {
    });
    dispatch({ type: GIFT_SAVE_FAIL, payload: e });
  }
  dispatch({ type: GIFT_END_LOADING });
};

export const updateGift = (gift, file) => async (dispatch) => {
  const oldImageUrl = gift.imageUrl;
  let imageUuid = uuid();
  try {
    if (oldImageUrl) {
      imageUuid = extractUuidFromImageUrl(oldImageUrl);
    }
  } catch (e) {
    dispatch({ type: GIFT_SAVE_FAIL, payload: e });
    return;
  }

  dispatch({ type: GIFT_LOADING });
  const fileRef = storage.child(`gifts/${imageUuid}`);
  const meta = { updatedAt: new Date() };
  const giftToSave = { ...gift, ...meta };
  if (file) {
    try {
      await fileRef.put(file);
      const fbImageUrl = await fileRef.getDownloadURL();
      giftToSave.imageUrl = extractUseFullImageUrl(fbImageUrl);
    } catch (e) {
      dispatch({ type: GIFT_SAVE_FAIL, payload: e });
    }
  }

  try {
    await gifts.doc(giftToSave.docId).set(giftToSave);
    dispatch({ type: GIFT_SAVE_SUCCESS, payload: { gift: giftToSave, isNew: false } });
    dispatch({ type: EVENT_GIFT, payload: { type: 'gift', code: 'updated', value: giftToSave } });
  } catch (e) {
    await fileRef.delete().catch(() => {
    });
    dispatch({ type: GIFT_SAVE_FAIL, payload: e });
  }
  dispatch({ type: GIFT_END_LOADING });
};

export const getGifts = () => async (dispatch) => {
  dispatch({ type: GIFT_LOADING });
  try {
    const giftList = await gifts.get().then((items) => items.docs.map((item) => item.data()));
    dispatch({ type: GIFT_FETCHED_ALL, payload: { gifts: giftList } });
  } catch (e) {
    dispatch({ type: GIFT_FETCH_FAIL, payload: e });
  }
  dispatch({ type: GIFT_END_LOADING });
};

export const removeGift = (gift) => async (dispatch) => {
  let imageUuid = null;
  if (gift.imageUrl) {
    imageUuid = extractUuidFromImageUrl(gift.imageUrl);
  }
  dispatch({ type: GIFT_LOADING });
  try {
    if (imageUuid) {
      const fileRef = storage.child(`gifts/${imageUuid}`);
      await fileRef.delete();
    }
  } catch (e) {
    console.log('Image not available'); // eslint-disable-line
  }
  try {
    await gifts.doc(gift.docId).delete();
    dispatch({ type: GIFT_DELETE_SUCCESS, payload: { id: gift.docId } });
    dispatch({ type: EVENT_GIFT, payload: { type: 'gift', code: 'removed', value: gift } });
  } catch (e) {
    dispatch({ type: GIFT_DELETE_FAIL, payload: e });
  }
  dispatch({ type: GIFT_END_LOADING });
};

export const updateStatus = (id, data) => async (dispatch) => {
  if (+new Date() > LOCK_DATE) {
    dispatch({ type: GIFT_UPDATE_STATUS_ERROR, payload: new Error('La liste est fermée.') });
    return;
  }
  dispatch({ type: GIFT_UPDATE_STATUS_LOADING });
  try {
    await gifts.doc(id).update(data);
    dispatch({ type: GIFT_UPDATE_STATUS_SUCCESS, payload: { docId: id, ...data } });
  } catch (e) {
    dispatch({ type: GIFT_UPDATE_STATUS_ERROR, payload: e });
  }
};

export const cleanUpdateStatusResults = (dispatch) => {
  dispatch({ type: GIFT_UPDATE_CLEAN });
};
