import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  IconButton, Button, Dialog, DialogActions, DialogContent, DialogTitle
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EmojiBear from '../Emoji/EmojiBear';
import EmojiHeart from '../Emoji/EmojiHeart';
import EmojiBaby from '../Emoji/EmojiBaby';
import EmojiBabyBottle from '../Emoji/EmojiBabyBottle';
import { getAppVersion } from '../../selectors/app';
import InformationModalLine from '../InformationModalLine/InformationModalLine';

class InformationModal extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    version: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
  };

  signIn() {
    this.props.history.push('/sign-in');
    this.props.onClose();
  }

  render() {
    const { open, onClose, version } = this.props;
    return (
      <React.Fragment>
        <Dialog open={open} onClose={() => onClose()} aria-label='modal informative' maxWidth='md'>
          <DialogTitle className="closable-modal__title">
            À propos
            <IconButton aria-label="close" onClick={() => onClose()}>
              <CloseIcon/>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <InformationModalLine
              title={'Développement du site :'}
              text={(<span>Morgan&nbsp;DENIS&nbsp;<EmojiBear/></span>)}/>
            <InformationModalLine
              title={'Tests et conseils esthétiques :'}
              text={(<span>Audrey&nbsp;BONHOMME&nbsp;<EmojiHeart/></span>)}
              className='mt-s'/>
            <InformationModalLine
              title={'Participation indispensable :'}
              text={(<span><EmojiBabyBottle/>Lucas&nbsp;<EmojiBaby click={() => this.signIn()}/></span>)}
              className='mt-s'/>
            <InformationModalLine
              title={'Version :'}
              text={(<span>{version}</span>)}
              className='mt-s'/>
            <InformationModalLine
              title={'Un problème ?'}
              text={(<a href='mailto:morgan.denis.60@gmail.com?subject=Liste%20de%20naissance'>morgan.denis.60@gmail.com</a>)}
              className='mt-s'/>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onClose()}>Fermer</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => ({
  version: getAppVersion(store)
});

export default connect(mapStateToProps)(withRouter(InformationModal));
