import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ExpansionPanel, ExpansionPanelSummary,
  ExpansionPanelDetails
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Rating from '@material-ui/lab/Rating';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import { formatNumber, getWebsite } from '../../services/utils';

export default class GiftTable extends React.PureComponent {
  static propTypes = {
    gifts: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    contributorsCol: PropTypes.bool,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
  };

  handleEdit(id) {
    this.props.onEdit(id);
  }

  handleDelete(gift) {
    this.props.onDelete(gift);
  }

  render() {
    const { gifts, title, contributorsCol } = this.props;
    const sortedGifts = gifts.sort((a, b) => a.name.localeCompare(b.name));
    return (
      <React.Fragment>
        <ExpansionPanel>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h3>{title} ({gifts.length})</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
          <TableContainer>
            <Table aria-label={title}>
              <TableHead>
                <TableRow>
                  <TableCell>Nom</TableCell>
                  <TableCell align="right">Actif</TableCell>
                  <TableCell align="right">Prix</TableCell>
                  <TableCell align="right">Besoin</TableCell>
                  <TableCell align="right">Site</TableCell>
                  <TableCell align="right">Type</TableCell>
                  { contributorsCol ? (<TableCell align="right">Contributeurs</TableCell>) : null }
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedGifts.map((gift) => (
                  <TableRow key={gift.docId}>
                    <TableCell component="th" scope="row">
                      {gift.name}
                    </TableCell>
                    <TableCell align="right">
                      {gift.isDisabled ? (<CloseIcon color='secondary'/>) : (<CheckIcon color='primary'/>)}
                    </TableCell>
                    <TableCell align="right">{formatNumber(gift.price)}&nbsp;€</TableCell>
                    <TableCell align="right">
                      <Rating name="besoin" value={gift.rate || 0} readOnly/>
                    </TableCell>
                    <TableCell align="right">
                      <a href={gift.link} target='_blank' rel='noreferrer noopener'>{getWebsite(gift.link)}</a>
                    </TableCell>
                    <TableCell align="right">{gift.type}</TableCell>
                    { contributorsCol ? (<TableCell align="right">{gift.contributions.map((g) => g.name).join(', ')}</TableCell>) : null }
                    <TableCell align='right'>
                      <IconButton aria-label="modifier" onClick={() => this.handleEdit(gift.docId)}>
                        <EditIcon/>
                      </IconButton>
                      <IconButton aria-label="supprimer" color="secondary"
                                  onClick={() => this.handleDelete(gift)}>
                        <DeleteIcon/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </React.Fragment>
    );
  }
}
