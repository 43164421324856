import React from 'react';
import PropTypes from 'prop-types';
import { Button, DialogActions, DialogContent } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import './SuccessAction.css';

export default class SuccessAction extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    actionName: PropTypes.string.isRequired
  };

  render() {
    const { onClose, actionName } = this.props;
    return (
      <React.Fragment>
        <DialogContent>
          <div className='col full justify-center'>
            <div className='row justify-center'>
              <CheckIcon className='success-action__icon'/>
            </div>
            <div className='row mt-l justify-center'>
              <h2 className='success-action__text center'>Votre {actionName} à bien été enregistrée.</h2>
            </div>
            <div className='row mt-m justify-center mb-l'>
              <h2 className='success-action__text'>Merci</h2>
            </div>
          </div>
        </DialogContent>
        <DialogActions className='success-action__button__row'>
          <Button onClick={() => onClose()} color='primary'>
            Terminer
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  }
}
