import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  InputAdornment,
  IconButton,
  Input,
  Button,
  Chip
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Rating from '@material-ui/lab/Rating';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { createGift, getGifts, updateGift } from '../../../actions/gift';
import { getGift, getGiftChanges, getGiftIsLoading } from '../../../selectors/gift';
import './GiftEdit.css';
import { spreadGift } from '../../../services/gift';
import { GIFT_TYPE } from '../../../const';

class GiftEdit extends React.PureComponent {
  static propTypes = {
    createGift: PropTypes.func.isRequired,
    updateGift: PropTypes.func.isRequired,
    fetchGifts: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    giftChanges: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    existingGift: PropTypes.object
  };

  constructor(props) {
    super(props);
    const docId = props.match.params.gift || null;
    this.state = {
      form: {
        ...spreadGift(),
        tag: ''
      },
      isEdition: !!docId,
      file: null,
      isPending: false
    };
    if (docId) {
      if (!this.props.existingGift) {
        this.state.isPending = true;
        this.props.fetchGifts();
      } else {
        this.state.form = {
          ...spreadGift(this.props.existingGift),
          tag: ''
        };
      }
    }
    document.title = 'Liste de naissance de Lucas - admin - édition';
  }

  componentDidUpdate(prevProps) {
    if (this.props.existingGift !== prevProps.existingGift) {
      this.setState({
        ...this.state,
        form: {
          ...spreadGift(this.props.existingGift),
          tag: ''
        },
        isPending: false
      });
    }
    if (this.props.giftChanges !== prevProps.giftChanges) {
      this.goToAdmin();
    }
  }

  removeContributor(index) {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        contributions: form.contributions.filter((contribution, i) => i !== index)
      }
    });
  }

  async submit() {
    const { form } = this.state;
    const gift = { ...form };
    delete gift.tag;
    delete gift.isEdition;
    const { file, isEdition } = this.state;
    if (!isEdition) {
      await this.props.createGift(gift, file);
    } else {
      await this.props.updateGift(gift, file);
    }
  }

  addTag() {
    const { form } = this.state;
    if (form.tag === '') return;
    form.tags.push(form.tag);
    this.setState({
      form: {
        ...form,
        tags: form.tags,
        tag: ''
      }
    });
  }

  onChange(key, event) {
    let value = event.target.value;
    if (key === 'rate') {
      value = parseInt(value, 10);
    }
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [key]: value
      }
    });
  }

  fileAdded() {
    this.setState({ file: document.querySelector('#file').files[0] });
  }

  handleRemoveTag(index) {
    const { form: { tags } } = this.state;
    tags.splice(index, 1);
    this.onChange('tags', { target: { value: tags } });
  }

  handleTagKeyUp(event) {
    if (event.keyCode === 13) {
      this.addTag();
    }
  }

  goToAdmin() {
    this.props.history.push('/admin/gifts');
  }

  render() {
    const { form, file, isPending } = this.state;
    const { isLoading } = this.props;
    if (isPending) {
      return null;
    }
    return (
      <div>
        <div className='row'>
          <div className="gift-edit-form full">
            <div className='col justify-center full'>
              <div className='row mt-m justify-center'>
                <h1>Création de cadeau</h1>
              </div>
              <div className='row mt-l'>
                <TextField id='gift-edit-form-name' label='Nom' placeholder='Nom' value={form.name} fullWidth
                           onChange={(e) => this.onChange('name', e)}/>
              </div>
              <div className='row mt-m'>
                <TextField id='gift-edit-form-price' type="number" label='Prix' placeholder='Prix' value={form.price}
                           fullWidth
                           onChange={(e) => this.onChange('price', e)}
                           InputLabelProps={{ shrink: true }}/>
              </div>
              <div className='row mt-m'>
                <TextField id='gift-edit-form-link' label='Lien' placeholder='Lien' value={form.link} fullWidth
                           onChange={(e) => this.onChange('link', e)}/>
              </div>
              <div className='row mt-m'>
                <TextField id='gift-edit-form-comment' label='Commentaire' placeholder='Commentaire' fullWidth
                           value={form.comment} onChange={(e) => this.onChange('comment', e)}/>
              </div>
              <div className='row mt-m'>
                <FormControl fullWidth>
                  <InputLabel id="gift-edit-form-type-label">Type</InputLabel>
                  <Select
                    labelId="gift-edit-form-type-label"
                    id="gift-edit-form-type"
                    value={form.type}
                    onChange={(e) => this.onChange('type', e)}
                  >
                    <MenuItem value={GIFT_TYPE.PARTICIPATION}>Participation</MenuItem>
                    <MenuItem value={GIFT_TYPE.ACHAT}>Achat</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className='row mt-m'>
                <FormControl fullWidth>
                  <InputLabel htmlFor="gift-edit-form-tag">Nom du tag</InputLabel>
                  <Input id='gift-edit-form-tag' placeholder='Tag'
                         value={form.tag}
                         onChange={(e) => this.onChange('tag', e)}
                         onKeyUp={(e) => this.handleTagKeyUp(e)}
                         endAdornment={
                           <InputAdornment position="end">
                             <IconButton
                               aria-label="ajouter le tag"
                               onClick={() => this.addTag()}
                               edge="end"
                             >
                               <AddIcon/>
                             </IconButton>
                           </InputAdornment>
                         }/>
                </FormControl>
              </div>
              {form.tags.length > 0 ? (
                <div className='row mt-m'>
                  <FormControlLabel
                    control={<div className='ml-s'>
                      {form.tags.map((tag, i) => (
                        <Chip
                          className='mr-xs mt-xs'
                          key={i + tag}
                          label={tag}
                          onDelete={() => this.handleRemoveTag(i)}
                        />
                      ))
                      }
                    </div>}
                    labelPlacement="start"
                    label="Tags"/>
                </div>) : null}
              <div className='row mt-m gift-edit-file-row'>
                {file ? (<div className='col mr-m justify-center'>
                  <div className='row'>
                    {file.name}
                  </div>
                </div>) : null}
                <div className='col'>
                  <div className='row'>
                    <Input type="file" name='file' id='file' className='gift-edit-file-row-input'
                           onChange={() => this.fileAdded()}/>
                    <label htmlFor="file">
                      <Button
                        variant="contained"
                        color="default"
                        startIcon={<CloudUploadIcon/>}
                        onClick={() => document.querySelector('#file').click()}
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
              </div>
              <div className='row mt-m'>
                <FormControlLabel
                  control={<Rating name="besoin" className='ml-xs' value={form.rate}
                                   onChange={(event) => this.onChange('rate', event)}/>}
                  labelPlacement="start"
                  label="Besoin"/>
              </div>
              <div className='row mt-m'>
                <FormControlLabel
                  control={<Switch checked={form.isDisabled} color='primary'
                                   onChange={() => this.onChange('isDisabled', { target: { value: !form.isDisabled } })}/>}
                  labelPlacement="start"
                  label="Désactivé"/>
              </div>
              <div className='row mt-m'>
                <FormControlLabel
                  control={<div className="ml-s">
                    {form.contributions.length > 0 ? (form.contributions.map((contribution, i) => (
                    <div className='row gift-edit__contribution__row' key={i}>
                      <div className='col justify-center gift-edit__contribution__icon mr-xs'>
                        <DeleteForeverIcon onClick={() => this.removeContributor(i)}/>
                      </div>
                      <div className='col justify-center'>
                        <span>{contribution.name} d&apos;un montant de {contribution.amount} €</span>
                      </div>
                    </div>))) : (<span>Aucune contribution</span>)}
                  </div>}
                  labelPlacement="start"
                  label="Contibutions"/>
              </div>
              <div className='row space-between mt-m mb-s'>
                <Button
                  startIcon={<ArrowBackIcon/>}
                  onClick={() => this.goToAdmin()}
                  disabled={isLoading}
                >
                  Retour
                </Button>
                <Button
                  color="primary"
                  startIcon={<SaveIcon/>}
                  onClick={() => this.submit()}
                >
                  Enregistrer
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store, ownProps) => ({
  giftChanges: getGiftChanges(store),
  isLoading: getGiftIsLoading(store),
  existingGift: getGift(store, ownProps.match.params.gift)
});

const mapDispatchToProps = (dispatch) => ({
  createGift: (data, file) => dispatch(createGift(data, file)),
  fetchGifts: () => dispatch(getGifts()),
  updateGift: (data, file) => dispatch(updateGift(data, file))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GiftEdit));
