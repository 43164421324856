import { version } from '../../package.json';
import { CHECK_LOCK, LOCK_DATE, WINDOW_RESIZE } from '../const';

const initialState = {
  version,
  width: 0,
  height: 0,
  isLocked: false
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  if (type === WINDOW_RESIZE) {
    return {
      ...state,
      ...payload
    };
  }
  if (type === CHECK_LOCK) {
    return {
      ...state,
      isLocked: +new Date() > LOCK_DATE
    };
  }
  return state;
}
