import { auth } from '../firebase';
import {
  SIGN_SUCCESS,
  SIGN_FAIL,
  AUTH_LOADING_FINISHED,
  AUTH_LOADING,
  SIGN_OUT
} from '../types/auth';
import { EVENT_AUTH } from '../types/event';

export const signIn = (email, password) => async (dispatch) => {
  dispatch({ type: AUTH_LOADING });
  try {
    const response = await auth.signInWithEmailAndPassword(email, password);
    dispatch({ type: SIGN_SUCCESS, payload: { user: { email: response.user.email } } });
    dispatch({ type: AUTH_LOADING_FINISHED });
    dispatch({ type: EVENT_AUTH, payload: { type: 'auth', code: 'connected', value: {} } });
  } catch (error) {
    dispatch({ type: SIGN_FAIL, payload: { error } });
    dispatch({ type: AUTH_LOADING_FINISHED });
  }
};

export const signOut = () => async (dispatch) => {
  dispatch({ type: AUTH_LOADING });
  dispatch({ type: EVENT_AUTH, payload: { type: 'auth', code: 'disconnected', value: {} } });
  try {
    await auth.signOut();
    dispatch({ type: SIGN_OUT });
    dispatch({ type: AUTH_LOADING_FINISHED });
  } catch (error) {
    dispatch({ type: AUTH_LOADING_FINISHED });
  }
};
