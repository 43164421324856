import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  FormControl, InputLabel, MenuItem, Select
} from '@material-ui/core';
import {
  getGiftIsLoading,
  getAllAvailableGifts,
  getAllBookedGifts,
  getAllDisabledGifts
} from '../../selectors/gift';
import { getGifts } from '../../actions/gift';
import './Gifts.css';
import '../../components/GiftCard/GiftCard.css';
import GiftDetailsModal from '../../components/GiftDetailsModal/GiftDetailsModal';
import Spinner from '../../components/Spinner/Spinner';
import { getAuthIsLoggedIn } from '../../selectors/auth';
import { getAppLock } from '../../selectors/app';
import GiftGroup from '../../components/GiftGroup/GiftGroup';
import AppLocked from '../../components/AppLocked/AppLocked';
import InfoMessage from '../../components/InfoMessage/InfoMessage';

class Gifts extends React.PureComponent {
  static propTypes = {
    fetch: PropTypes.func.isRequired,
    availableGifts: PropTypes.array.isRequired,
    bookedGifts: PropTypes.array.isRequired,
    disabledGifts: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    isAppLocked: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedGift: null,
      dialogVisible: false,
      form: {
        order: 'price|asc'
      }
    };
    document.title = 'Liste de naissance de Lucas';
    const { availableGifts, disabledGifts, bookedGifts } = this.props;
    if (!availableGifts.length && !bookedGifts.length && !disabledGifts.length) {
      this.props.fetch();
    }
  }

  onChange(key, event) {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [key]: event.target.value
      }
    });
  }

  showDialog(gift) {
    this.setState({ dialogVisible: true, selectedGift: gift });
  }

  closeDialog() {
    this.setState({ ...this.state, dialogVisible: false });
  }

  render() {
    const {
      isLoading, isLoggedIn, availableGifts, disabledGifts, bookedGifts, isAppLocked
    } = this.props;
    const { form, selectedGift, dialogVisible } = this.state;
    if (isLoading && !availableGifts.length && !disabledGifts.length && !bookedGifts.length) {
      return (<Spinner/>);
    }
    if (isAppLocked) {
      return (<AppLocked/>);
    }
    return (<React.Fragment>
      <div className='row'>
        <div className='col full'>
          <InfoMessage/>
          <div className='row mt-m mb-s space-between'>
            <div className='row space-between gifts__form-bloc'>
              <div className='col gifts__form-bloc__col'>
                <div className='row gifts__form-bloc__col__row'>
                  <FormControl>
                    <InputLabel id="gift-edit-form-type-label">Ordre</InputLabel>
                    <Select
                      labelId="gift-edit-form-type-label"
                      id="gift-edit-form-type"
                      value={form.order}
                      onChange={(e) => this.onChange('order', e)}
                      placeholder="Ordre d'affichage"
                    >
                      <MenuItem value="newest|desc">Nouveauté</MenuItem>
                      <MenuItem value="price|asc">Prix (du - au + cher)</MenuItem>
                      <MenuItem value="price|desc">Prix (du + au - cher)</MenuItem>
                      <MenuItem value="rate|asc">Nécessité (du - au + nécessaire)</MenuItem>
                      <MenuItem value="rate|desc">Nécessité (du + au - nécessaire)</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
          <GiftGroup gifts={availableGifts} title='Cadeaux disponibles' order={form.order} clickEvent={(gift) => this.showDialog(gift)}/>
          <GiftGroup gifts={bookedGifts} title='Cadeaux réservés' order={form.order} clickEvent={(gift) => this.showDialog(gift)}/>
          { isLoggedIn ? (
            <GiftGroup gifts={disabledGifts} title='Cadeaux désactivés' order={form.order} clickEvent={(gift) => this.showDialog(gift)}/>
          ) : null }
        </div>
        <GiftDetailsModal
          gift={selectedGift} back={() => this.closeDialog()} isVisible={dialogVisible} open={dialogVisible}
          onClose={() => this.closeDialog()}/>
      </div>
    </React.Fragment>);
  }
}

const mapStateToProps = (store) => ({
  isLoading: getGiftIsLoading(store),
  isLoggedIn: getAuthIsLoggedIn(store),
  availableGifts: getAllAvailableGifts(store),
  bookedGifts: getAllBookedGifts(store),
  disabledGifts: getAllDisabledGifts(store),
  isAppLocked: getAppLock(store)
});

const mapDispatchToProps = (dispatch) => ({
  fetch: () => dispatch(getGifts())
});

export default connect(mapStateToProps, mapDispatchToProps)(Gifts);
