import React from 'react';
import PropTypes from 'prop-types';

export default class EmojiBaby extends React.PureComponent {
  static propTypes = { click: PropTypes.func.isRequired };

  render() {
    return (<span className='emoji' role='img' aria-label='Baby' onClick={this.props.click}>👶️</span>);
  }
}
